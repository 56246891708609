// @generated by protoc-gen-connect-es v0.13.2 with parameter "target=ts"
// @generated from file finance_tool/v1/service.proto (package finance_tool.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetCreditApplicationStatusResponse, GetFinanceLinkResponse, GetProposalInformationResponse, GetProtectedFinanceRequest, GetSendDocsLinkResponse, SubmitCreditApplicationRequest, SubmitCreditApplicationResponse } from "./service_pb.ts";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service finance_tool.v1.finance_tool_service
 */
export const finance_tool_service = {
  typeName: "finance_tool.v1.finance_tool_service",
  methods: {
    /**
     * @generated from rpc finance_tool.v1.finance_tool_service.GetLink
     */
    getLink: {
      name: "GetLink",
      I: GetProtectedFinanceRequest,
      O: GetFinanceLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc finance_tool.v1.finance_tool_service.GetProposalInformation
     */
    getProposalInformation: {
      name: "GetProposalInformation",
      I: GetProtectedFinanceRequest,
      O: GetProposalInformationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc finance_tool.v1.finance_tool_service.SubmitCreditApplication
     */
    submitCreditApplication: {
      name: "SubmitCreditApplication",
      I: SubmitCreditApplicationRequest,
      O: SubmitCreditApplicationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc finance_tool.v1.finance_tool_service.GetCreditApplicationStatus
     */
    getCreditApplicationStatus: {
      name: "GetCreditApplicationStatus",
      I: GetProtectedFinanceRequest,
      O: GetCreditApplicationStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc finance_tool.v1.finance_tool_service.GetSendDocsLink
     */
    getSendDocsLink: {
      name: "GetSendDocsLink",
      I: GetProtectedFinanceRequest,
      O: GetSendDocsLinkResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

