import React from 'react'
import * as ReactDOM from "react-dom/client";
import GlobalStyles from './styles/GlobalStyles'
import Global from "./routes/global.tsx";
import Goodleap from "./routes/goodleap.tsx";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: '/finance-application/:id/:uuid',
    element: <Global />,
  },
  {
    path: '/elios/:id/:uuid',
    element: <Global />,
  },
  {
    path: '/goodleap/:id/:uuid',
    element: <Goodleap />,
  },
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <GlobalStyles />
    <RouterProvider router={router} />
  </>
);