/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'
import { useEffect, useState } from 'react';
import {
  GetFinanceLinkResponse,
} from '../gen/finance_tool/v1/service_pb.js'

import { useParams } from 'react-router-dom';

import dataProvider from '../network.ts';

export default function Global() {
    const Scenes = Object.freeze({
        LOADING: "loading",
        ERROR: "error",
    });

    let { id, uuid } = useParams();
    
    const [scene, setScene] = useState(Scenes.LOADING);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        async function initialFetch(){
        try {
            if (!id || !uuid) {
                throw new Error('Invalid parameters')
            }
            const response: GetFinanceLinkResponse = await dataProvider.getLink({ id, uuid})
            if (response && response.success && response.redirect) {
                window.location.replace(response.redirect)
            }
        } catch (error) {
            setErrorMessage(error.response.data.message)
            setScene(Scenes.ERROR)
            console.error('Error fetching data: ', error);
        }
    }
    initialFetch()
    }, []);

    const renderFn = () => {
        switch (scene) {
            case Scenes.LOADING:
                return (
                    <div tw="min-h-screen overflow-x-hidden flex flex-col items-center justify-center w-full" style={{ background: '#222' }}>
                        <p tw="text-white text-lg tracking-widest">loading finance</p>
                    </div>
                )
            case Scenes.ERROR:
                return (
                    <div tw="min-h-screen overflow-x-hidden flex flex-col items-center justify-center w-full" style={{ background: '#222' }}>
                        <p tw="text-white text-lg tracking-widest">{errorMessage}</p>
                    </div>
                )
            default:
                return <div>Unknown scene</div>
        }
    }

    return renderFn()
}