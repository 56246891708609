/** @jsxImportSource @emotion/react */
import tw, { styled } from 'twin.macro'

const H2 = styled.h2(() => [
  tw`text-lg font-semibold leading-7 text-gray-900`,
])


const StyledInput = styled.input(({ isReadOnly, isRequired, isEmpty }) => [
  tw`px-1.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6 `,
  isReadOnly && tw`opacity-50 ring-0 focus:ring-0 cursor-not-allowed`,
  isRequired && isEmpty && tw`border-red-500 ring-red-500 focus:ring-red-500`,
])

const StyledRequiredDiv = styled.div(({isRequired, isEmpty }) => [
  tw`px-1.5 block w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 `,
  isRequired && isEmpty && tw`border-red-500 ring-red-500 focus:ring-red-500`,
])

const StyledDatePickerInput = styled.input(() => [
  tw`block w-full rounded-md border-0 text-gray-900 ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6 `,
])

const StyledCheckbox = styled.input(({ isRequired, isEmpty }) => [
  tw`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600`,
  isRequired && isEmpty && tw`border-red-500 ring-red-500 focus:ring-red-500`,
])

const StyledSelect = styled.select(({isRequired, isEmpty}) => [
  tw`px-1.5 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-indigo-100 sm:max-w-xs sm:text-sm sm:leading-6 `,
  isRequired && isEmpty && tw`border-red-500 ring-red-500 focus:ring-red-500`,
])

const StyledLabel = styled.label(() => [
  tw`block text-sm font-medium leading-6 text-gray-900`,
])

const P = styled.p(() => [tw`text-sm leading-6 text-gray-700`])

export {
    P,
    H2,
    StyledInput,
    StyledSelect,
    StyledLabel,
    StyledCheckbox,
    StyledRequiredDiv,
    StyledDatePickerInput
}
