import React from 'react';
import tw from 'twin.macro';
import { StyledDatePickerInput, StyledRequiredDiv } from './StyledComponents';
interface DatePickerProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ isRequired, isEmpty, value, onChange }) =>
{
    return (
        <StyledRequiredDiv
            isRequired = {isRequired}
            isEmpty = {isEmpty}
            >
            <StyledDatePickerInput
                type="date"
                value={value}
                onChange={onChange}
            />
        </StyledRequiredDiv>
    );
};

export default DatePicker;
