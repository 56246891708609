import React from 'react';
import tw from 'twin.macro';
import { StyledSelect, StyledRequiredDiv } from './StyledComponents';
interface DropDownProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DropDown: React.FC<DropDownProps> = ({ isRequired, isEmpty, onChange, children }) =>
{
    return (
        <StyledRequiredDiv
            isRequired = {isRequired}
            isEmpty = {isEmpty}
            >
            <StyledSelect
                onChange={onChange}
            >
                {children}
            </StyledSelect>
        </StyledRequiredDiv>
    );
};

export default DropDown;
