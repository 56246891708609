import { proto3 } from "@bufbuild/protobuf";

let integrationsUrl = 'https://integrations.sunobiapp.com'
if(process.env.REACT_APP_IS_DEVELOPMENT){
    integrationsUrl = "https://integrations.sunobiapp.com"
}

import { PromiseClient, Transport } from "@connectrpc/connect";
import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { finance_tool_service } from "./gen/finance_tool/v1/service_connect.ts";
import {
    GetProtectedFinanceRequest,
    GetFinanceLinkResponse,
    GetProposalInformationResponse,
    GetCreditApplicationStatusResponse, 
    GetSendDocsLinkResponse,
    SubmitCreditApplicationRequest,
    SubmitCreditApplicationHomeOwnership,
    SubmitCreditApplicationCitizenshipStatus,
    SubmitCreditApplicationEmploymentStatus,
    SubmitCreditApplicationHomeOccupancy,
    
} from "./gen/finance_tool/v1/service_pb.ts";


import { getProposalParams, submitCreditApplicationParams } from "./types";

const transport = createConnectTransport({
    baseUrl: integrationsUrl,
    //useBinaryFormat: true,
});


const getEnum = (specificEnum: any, name: string) => {
    const metadata = proto3.getEnumType(specificEnum)
    const enumArray = metadata.values;
    const enumItem = enumArray.find((item: any) => item.name === name);

    const enumTarget = enumItem ? enumItem.no : false
    return enumTarget
}

class DataProvider{
    
    client: PromiseClient<typeof finance_tool_service>;

    constructor(transport: Transport) {        
        this.client = createPromiseClient(finance_tool_service, transport);
    }

    logger = (method: string, data: any) => {
        console.log({ resourceName: finance_tool_service.typeName, method, data })
    }

    getStatus = async (params: getProposalParams ) => {
        this.logger('getStatus param', params)
        const { id, uuid } = params
        const req = new GetProtectedFinanceRequest({proposalID: parseInt(id), sunobiSecurity: uuid})
        this.logger('getStatus Req', req)
        const res : GetCreditApplicationStatusResponse = await this.client.getCreditApplicationStatus(req)
        this.logger('getStatus Res', res)
        return res
    }

    getLink = async (params : getProposalParams) => {
      this.logger('getLink param', params)
      const { id, uuid } = params
      const req = new GetProtectedFinanceRequest({proposalID: parseInt(id), sunobiSecurity: uuid})
      this.logger('getLink Req', req)
      const res : GetFinanceLinkResponse = await this.client.getLink(req)
      this.logger('getLink Res', res)
      return res
    }

    getAccountInformation = async (params : getProposalParams) => {
        this.logger('getAccountInformation Params', params)
        const { id, uuid } = params
        const req = new GetProtectedFinanceRequest({proposalID: parseInt(id), sunobiSecurity: uuid})
        this.logger('getAccountInformation Req', req)
        const res : GetProposalInformationResponse = await this.client.getProposalInformation(req)
        this.logger('getAccountInformation Res', res)
        return res
    }
    submitAccountInformation = async (params : submitCreditApplicationParams) => {
        this.logger('submitApplication Params', params)
        const { id, uuid } = params
        const req = new SubmitCreditApplicationRequest({proposalID: parseInt(id), sunobiSecurity: uuid})
        if (params.ssn) req.ssn = params.ssn
        if (params.dob) req.dob = params.dob
        if (params.annualIncome) req.annualIncome = parseFloat(params.annualIncome)
        if (params.employer) req.employer = params.employer
        if (params.employmentOccupation) req.employmentOccupation = params.employmentOccupation
        if (params.employmentDuration) req.employmentDuration = parseFloat(params.employmentDuration)
        if (params.disclosureToken) req.disclosureToken = params.disclosureToken
        if (params.signatureImage) req.signatureImage = params.signatureImage
        
        const employmentStatus: SubmitCreditApplicationEmploymentStatus | boolean = getEnum(SubmitCreditApplicationEmploymentStatus, params.employmentStatus)
        if (employmentStatus) req.employmentStatus = employmentStatus
        
        const homeOccupancy : SubmitCreditApplicationHomeOccupancy | boolean = getEnum(SubmitCreditApplicationHomeOccupancy, params.homeOccupancy)
        if (homeOccupancy) req.homeOccupancy = homeOccupancy

        const homeOwnership : SubmitCreditApplicationHomeOwnership | boolean = getEnum(SubmitCreditApplicationHomeOwnership, params.homeOwnership)
        if (homeOwnership) req.homeOwnership = homeOwnership

        const citizenshipStatus : SubmitCreditApplicationCitizenshipStatus | boolean = getEnum(SubmitCreditApplicationCitizenshipStatus, params.citizenshipStatus)
        if (citizenshipStatus) req.citizenshipStatus = citizenshipStatus

        this.logger('submitAccountInformation Req', req)
        try
        {
            const res = await this.client.submitCreditApplication(req)
            this.logger('submitAccountInformation Res', res)
            return res
        }
        catch (error)
        {
            const regex = /"description":"(.*?)",/g;
            let match;
            let message = "";
            let messages: string[] = [];
            while ((match = regex.exec(error.message)) !== null)
            {
                messages.push(match[1].replace(/\\/g, "").replace(/"/g, "").replace(/.value/g, ""));
            }
            let uniqueMessages: string[] = [...new Set(messages)];
            for(let i = 0; i < uniqueMessages.length; i++)
            {
                message += uniqueMessages[i] + "\n";
            }
            alert(message.trim());
        }
    }

    sendDocs = async (params : getProposalParams) => {
        this.logger('sendDocs Params', params)
        const { id, uuid } = params
        const req = new GetProtectedFinanceRequest({proposalID: parseInt(id), sunobiSecurity: uuid})
        this.logger('sendDocs Req', req)
        const res : GetSendDocsLinkResponse = await this.client.getSendDocsLink(req)
        this.logger('sendDocs Res', res)
        return res
    }

}


const dataProvider = new DataProvider(transport)

export default dataProvider




