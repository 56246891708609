// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file finance_tool/v1/service.proto (package finance_tool.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum finance_tool.v1.SubmitCreditApplicationEmploymentStatus
 */
export enum SubmitCreditApplicationEmploymentStatus {
  /**
   * @generated from enum value: EMPLOYED = 0;
   */
  EMPLOYED = 0,

  /**
   * @generated from enum value: NOT_EMPLOYED = 1;
   */
  NOT_EMPLOYED = 1,

  /**
   * @generated from enum value: RETIRED = 2;
   */
  RETIRED = 2,

  /**
   * @generated from enum value: OTHER_EMPLOYMENT = 3;
   */
  OTHER_EMPLOYMENT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SubmitCreditApplicationEmploymentStatus)
proto3.util.setEnumType(SubmitCreditApplicationEmploymentStatus, "finance_tool.v1.SubmitCreditApplicationEmploymentStatus", [
  { no: 0, name: "EMPLOYED" },
  { no: 1, name: "NOT_EMPLOYED" },
  { no: 2, name: "RETIRED" },
  { no: 3, name: "OTHER_EMPLOYMENT" },
]);

/**
 * @generated from enum finance_tool.v1.SubmitCreditApplicationHomeOccupancy
 */
export enum SubmitCreditApplicationHomeOccupancy {
  /**
   * @generated from enum value: PRIMARY = 0;
   */
  PRIMARY = 0,

  /**
   * @generated from enum value: SECONDARY = 1;
   */
  SECONDARY = 1,

  /**
   * @generated from enum value: INVESTMENT = 2;
   */
  INVESTMENT = 2,

  /**
   * @generated from enum value: OTHER_OCCUPANCY = 3;
   */
  OTHER_OCCUPANCY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(SubmitCreditApplicationHomeOccupancy)
proto3.util.setEnumType(SubmitCreditApplicationHomeOccupancy, "finance_tool.v1.SubmitCreditApplicationHomeOccupancy", [
  { no: 0, name: "PRIMARY" },
  { no: 1, name: "SECONDARY" },
  { no: 2, name: "INVESTMENT" },
  { no: 3, name: "OTHER_OCCUPANCY" },
]);

/**
 * @generated from enum finance_tool.v1.SubmitCreditApplicationHomeOwnership
 */
export enum SubmitCreditApplicationHomeOwnership {
  /**
   * @generated from enum value: OWNED_WITH_MORTGAGE = 0;
   */
  OWNED_WITH_MORTGAGE = 0,

  /**
   * @generated from enum value: OWNED_FREE_AND_CLEAR = 1;
   */
  OWNED_FREE_AND_CLEAR = 1,

  /**
   * @generated from enum value: NOT_OWNED = 2;
   */
  NOT_OWNED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SubmitCreditApplicationHomeOwnership)
proto3.util.setEnumType(SubmitCreditApplicationHomeOwnership, "finance_tool.v1.SubmitCreditApplicationHomeOwnership", [
  { no: 0, name: "OWNED_WITH_MORTGAGE" },
  { no: 1, name: "OWNED_FREE_AND_CLEAR" },
  { no: 2, name: "NOT_OWNED" },
]);

/**
 * @generated from enum finance_tool.v1.SubmitCreditApplicationCitizenshipStatus
 */
export enum SubmitCreditApplicationCitizenshipStatus {
  /**
   * @generated from enum value: US_CITIZEN = 0;
   */
  US_CITIZEN = 0,

  /**
   * @generated from enum value: LAWFUL_PERMANENT_RESIDENT_ALIEN = 1;
   */
  LAWFUL_PERMANENT_RESIDENT_ALIEN = 1,

  /**
   * @generated from enum value: OTHER_STATUS = 2;
   */
  OTHER_STATUS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SubmitCreditApplicationCitizenshipStatus)
proto3.util.setEnumType(SubmitCreditApplicationCitizenshipStatus, "finance_tool.v1.SubmitCreditApplicationCitizenshipStatus", [
  { no: 0, name: "US_CITIZEN" },
  { no: 1, name: "LAWFUL_PERMANENT_RESIDENT_ALIEN" },
  { no: 2, name: "OTHER_STATUS" },
]);

/**
 * @generated from enum finance_tool.v1.FinanceApplicationScene
 */
export enum FinanceApplicationScene {
  /**
   * @generated from enum value: LOADING = 0;
   */
  LOADING = 0,

  /**
   * @generated from enum value: APPLICATION = 1;
   */
  APPLICATION = 1,

  /**
   * @generated from enum value: SEND_DOCS = 2;
   */
  SEND_DOCS = 2,

  /**
   * @generated from enum value: NEED_STIPULATIONS = 3;
   */
  NEED_STIPULATIONS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(FinanceApplicationScene)
proto3.util.setEnumType(FinanceApplicationScene, "finance_tool.v1.FinanceApplicationScene", [
  { no: 0, name: "LOADING" },
  { no: 1, name: "APPLICATION" },
  { no: 2, name: "SEND_DOCS" },
  { no: 3, name: "NEED_STIPULATIONS" },
]);

/**
 * @generated from message finance_tool.v1.GetProtectedFinanceRequest
 */
export class GetProtectedFinanceRequest extends Message<GetProtectedFinanceRequest> {
  /**
   * @generated from field: int32 proposalID = 1;
   */
  proposalID = 0;

  /**
   * @generated from field: string sunobiSecurity = 2;
   */
  sunobiSecurity = "";

  constructor(data?: PartialMessage<GetProtectedFinanceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.GetProtectedFinanceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proposalID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "sunobiSecurity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProtectedFinanceRequest {
    return new GetProtectedFinanceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProtectedFinanceRequest {
    return new GetProtectedFinanceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProtectedFinanceRequest {
    return new GetProtectedFinanceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProtectedFinanceRequest | PlainMessage<GetProtectedFinanceRequest> | undefined, b: GetProtectedFinanceRequest | PlainMessage<GetProtectedFinanceRequest> | undefined): boolean {
    return proto3.util.equals(GetProtectedFinanceRequest, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.GetFinanceLinkResponse
 */
export class GetFinanceLinkResponse extends Message<GetFinanceLinkResponse> {
  /**
   * @generated from field: string redirect = 1;
   */
  redirect = "";

  /**
   * @generated from field: bool success = 2;
   */
  success = false;

  /**
   * @generated from field: string error = 3;
   */
  error = "";

  constructor(data?: PartialMessage<GetFinanceLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.GetFinanceLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "redirect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFinanceLinkResponse {
    return new GetFinanceLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFinanceLinkResponse {
    return new GetFinanceLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFinanceLinkResponse {
    return new GetFinanceLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFinanceLinkResponse | PlainMessage<GetFinanceLinkResponse> | undefined, b: GetFinanceLinkResponse | PlainMessage<GetFinanceLinkResponse> | undefined): boolean {
    return proto3.util.equals(GetFinanceLinkResponse, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.DisclosureSection
 */
export class DisclosureSection extends Message<DisclosureSection> {
  /**
   * @generated from field: bool boldText = 1;
   */
  boldText = false;

  /**
   * @generated from field: bool checkBox = 2;
   */
  checkBox = false;

  /**
   * @generated from field: bool signatureBox = 3;
   */
  signatureBox = false;

  /**
   * @generated from field: int32 sort = 4;
   */
  sort = 0;

  /**
   * @generated from field: string text = 5;
   */
  text = "";

  constructor(data?: PartialMessage<DisclosureSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.DisclosureSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "boldText", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "checkBox", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "signatureBox", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "sort", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DisclosureSection {
    return new DisclosureSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DisclosureSection {
    return new DisclosureSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DisclosureSection {
    return new DisclosureSection().fromJsonString(jsonString, options);
  }

  static equals(a: DisclosureSection | PlainMessage<DisclosureSection> | undefined, b: DisclosureSection | PlainMessage<DisclosureSection> | undefined): boolean {
    return proto3.util.equals(DisclosureSection, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.GetProposalInformationResponse
 */
export class GetProposalInformationResponse extends Message<GetProposalInformationResponse> {
  /**
   * @generated from field: string primaryName = 1;
   */
  primaryName = "";

  /**
   * @generated from field: string secondaryName = 2;
   */
  secondaryName = "";

  /**
   * @generated from field: string addressString = 3;
   */
  addressString = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: bool cosign = 6;
   */
  cosign = false;

  /**
   * @generated from field: string repName = 100;
   */
  repName = "";

  /**
   * @generated from field: string repCompany = 101;
   */
  repCompany = "";

  /**
   * @generated from field: string disclosuresToken = 200;
   */
  disclosuresToken = "";

  /**
   * @generated from field: repeated finance_tool.v1.DisclosureSection disclosureSections = 201;
   */
  disclosureSections: DisclosureSection[] = [];

  /**
   * @generated from field: string installerName = 300;
   */
  installerName = "";

  /**
   * @generated from field: string installTeamName = 301;
   */
  installTeamName = "";

  /**
   * @generated from field: int32 systemSizeInWatts = 302;
   */
  systemSizeInWatts = 0;

  /**
   * @generated from field: int32 numberOfPanels = 303;
   */
  numberOfPanels = 0;

  /**
   * @generated from field: int32 estimatedAnnualProductionInWatts = 304;
   */
  estimatedAnnualProductionInWatts = 0;

  /**
   * @generated from field: int32 initialPaymentInCents = 305;
   */
  initialPaymentInCents = 0;

  /**
   * @generated from field: double tpoEscalator = 306;
   */
  tpoEscalator = 0;

  /**
   * @generated from field: double tpoKwhRate = 307;
   */
  tpoKwhRate = 0;

  /**
   * @generated from field: int32 utilityCostInCents = 308;
   */
  utilityCostInCents = 0;

  /**
   * @generated from field: int32 utilityConsumptionInWatts = 309;
   */
  utilityConsumptionInWatts = 0;

  /**
   * @generated from field: double utilityEscalator = 310;
   */
  utilityEscalator = 0;

  constructor(data?: PartialMessage<GetProposalInformationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.GetProposalInformationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "primaryName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "secondaryName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "addressString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "cosign", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "repName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "repCompany", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 200, name: "disclosuresToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 201, name: "disclosureSections", kind: "message", T: DisclosureSection, repeated: true },
    { no: 300, name: "installerName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 301, name: "installTeamName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 302, name: "systemSizeInWatts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 303, name: "numberOfPanels", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 304, name: "estimatedAnnualProductionInWatts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 305, name: "initialPaymentInCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 306, name: "tpoEscalator", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 307, name: "tpoKwhRate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 308, name: "utilityCostInCents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 309, name: "utilityConsumptionInWatts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 310, name: "utilityEscalator", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProposalInformationResponse {
    return new GetProposalInformationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProposalInformationResponse {
    return new GetProposalInformationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProposalInformationResponse {
    return new GetProposalInformationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProposalInformationResponse | PlainMessage<GetProposalInformationResponse> | undefined, b: GetProposalInformationResponse | PlainMessage<GetProposalInformationResponse> | undefined): boolean {
    return proto3.util.equals(GetProposalInformationResponse, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.SubmitCreditApplicationRequest
 */
export class SubmitCreditApplicationRequest extends Message<SubmitCreditApplicationRequest> {
  /**
   * @generated from field: int32 proposalID = 1;
   */
  proposalID = 0;

  /**
   * @generated from field: string sunobiSecurity = 2;
   */
  sunobiSecurity = "";

  /**
   * @generated from field: string ssn = 3;
   */
  ssn = "";

  /**
   * @generated from field: string dob = 4;
   */
  dob = "";

  /**
   * @generated from field: double annualIncome = 5;
   */
  annualIncome = 0;

  /**
   * @generated from field: string employer = 6;
   */
  employer = "";

  /**
   * @generated from field: finance_tool.v1.SubmitCreditApplicationEmploymentStatus employmentStatus = 7;
   */
  employmentStatus = SubmitCreditApplicationEmploymentStatus.EMPLOYED;

  /**
   * @generated from field: string employmentOccupation = 8;
   */
  employmentOccupation = "";

  /**
   * @generated from field: double employmentDuration = 9;
   */
  employmentDuration = 0;

  /**
   * @generated from field: finance_tool.v1.SubmitCreditApplicationHomeOccupancy homeOccupancy = 10;
   */
  homeOccupancy = SubmitCreditApplicationHomeOccupancy.PRIMARY;

  /**
   * @generated from field: finance_tool.v1.SubmitCreditApplicationHomeOwnership homeOwnership = 11;
   */
  homeOwnership = SubmitCreditApplicationHomeOwnership.OWNED_WITH_MORTGAGE;

  /**
   * @generated from field: finance_tool.v1.SubmitCreditApplicationCitizenshipStatus citizenshipStatus = 12;
   */
  citizenshipStatus = SubmitCreditApplicationCitizenshipStatus.US_CITIZEN;

  /**
   * @generated from field: string disclosureToken = 13;
   */
  disclosureToken = "";

  /**
   * @generated from field: string signatureImage = 14;
   */
  signatureImage = "";

  constructor(data?: PartialMessage<SubmitCreditApplicationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.SubmitCreditApplicationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proposalID", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "sunobiSecurity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ssn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "dob", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "annualIncome", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "employer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "employmentStatus", kind: "enum", T: proto3.getEnumType(SubmitCreditApplicationEmploymentStatus) },
    { no: 8, name: "employmentOccupation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "employmentDuration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "homeOccupancy", kind: "enum", T: proto3.getEnumType(SubmitCreditApplicationHomeOccupancy) },
    { no: 11, name: "homeOwnership", kind: "enum", T: proto3.getEnumType(SubmitCreditApplicationHomeOwnership) },
    { no: 12, name: "citizenshipStatus", kind: "enum", T: proto3.getEnumType(SubmitCreditApplicationCitizenshipStatus) },
    { no: 13, name: "disclosureToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "signatureImage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitCreditApplicationRequest {
    return new SubmitCreditApplicationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitCreditApplicationRequest {
    return new SubmitCreditApplicationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitCreditApplicationRequest {
    return new SubmitCreditApplicationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitCreditApplicationRequest | PlainMessage<SubmitCreditApplicationRequest> | undefined, b: SubmitCreditApplicationRequest | PlainMessage<SubmitCreditApplicationRequest> | undefined): boolean {
    return proto3.util.equals(SubmitCreditApplicationRequest, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.SubmitCreditApplicationResponse
 */
export class SubmitCreditApplicationResponse extends Message<SubmitCreditApplicationResponse> {
  /**
   * @generated from field: bool success = 2;
   */
  success = false;

  /**
   * @generated from field: string error = 3;
   */
  error = "";

  constructor(data?: PartialMessage<SubmitCreditApplicationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.SubmitCreditApplicationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "success", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitCreditApplicationResponse {
    return new SubmitCreditApplicationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitCreditApplicationResponse {
    return new SubmitCreditApplicationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitCreditApplicationResponse {
    return new SubmitCreditApplicationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitCreditApplicationResponse | PlainMessage<SubmitCreditApplicationResponse> | undefined, b: SubmitCreditApplicationResponse | PlainMessage<SubmitCreditApplicationResponse> | undefined): boolean {
    return proto3.util.equals(SubmitCreditApplicationResponse, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.GetCreditApplicationStatusResponse
 */
export class GetCreditApplicationStatusResponse extends Message<GetCreditApplicationStatusResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: bool hasError = 2;
   */
  hasError = false;

  /**
   * @generated from field: string errorMessage = 3;
   */
  errorMessage = "";

  /**
   * @generated from field: finance_tool.v1.FinanceApplicationScene sceneToShow = 4;
   */
  sceneToShow = FinanceApplicationScene.LOADING;

  /**
   * @generated from field: bool docsSent = 5;
   */
  docsSent = false;

  /**
   * Temporary, client usage of this needs to be ported over to explicit fields
   *
   * @generated from field: string goodleapApplicationJson = 6;
   */
  goodleapApplicationJson = "";

  constructor(data?: PartialMessage<GetCreditApplicationStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.GetCreditApplicationStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "hasError", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "errorMessage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sceneToShow", kind: "enum", T: proto3.getEnumType(FinanceApplicationScene) },
    { no: 5, name: "docsSent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "goodleapApplicationJson", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCreditApplicationStatusResponse {
    return new GetCreditApplicationStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCreditApplicationStatusResponse {
    return new GetCreditApplicationStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCreditApplicationStatusResponse {
    return new GetCreditApplicationStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCreditApplicationStatusResponse | PlainMessage<GetCreditApplicationStatusResponse> | undefined, b: GetCreditApplicationStatusResponse | PlainMessage<GetCreditApplicationStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetCreditApplicationStatusResponse, a, b);
  }
}

/**
 * @generated from message finance_tool.v1.GetSendDocsLinkResponse
 */
export class GetSendDocsLinkResponse extends Message<GetSendDocsLinkResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: bool hasError = 2;
   */
  hasError = false;

  /**
   * @generated from field: string errorMessage = 3;
   */
  errorMessage = "";

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  constructor(data?: PartialMessage<GetSendDocsLinkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "finance_tool.v1.GetSendDocsLinkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "hasError", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "errorMessage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSendDocsLinkResponse {
    return new GetSendDocsLinkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSendDocsLinkResponse {
    return new GetSendDocsLinkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSendDocsLinkResponse {
    return new GetSendDocsLinkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSendDocsLinkResponse | PlainMessage<GetSendDocsLinkResponse> | undefined, b: GetSendDocsLinkResponse | PlainMessage<GetSendDocsLinkResponse> | undefined): boolean {
    return proto3.util.equals(GetSendDocsLinkResponse, a, b);
  }
}

