import React from 'react';
import tw from 'twin.macro';
const ButtonWrapper = tw.div`
    flex
    items-center
    justify-center
    bg-blue-500
    text-white
    font-bold
    py-2
    px-4
    rounded-full
    cursor-pointer
    hover:bg-blue-600
    focus:outline-none
    focus:ring-2
    focus:ring-blue-500
    focus:ring-opacity-50
`;
interface FileUploadButtonProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onChange }) => {
    // Reference to the hidden file input
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        // Trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                onChange={onChange}
                style={{ display: 'none' }}
            />
            <ButtonWrapper onClick={handleButtonClick}>UPLOAD</ButtonWrapper>
        </div>
    );
};

export default FileUploadButton;
