/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'

import animatedLoading from '../assets/animatedloading.gif';

import { proto3 } from '@bufbuild/protobuf'

import React, { map, useEffect, useRef, useState } from 'react'
import bankOnly from '../assets/bankOnly.png'
import bigTick from '../assets/bigTick.png'
import sendDocsIcon from '../assets/sendDocsIcon.png'
import signDocsIconBlack from '../assets/signDocsIcon.png'
import signDocsIcon from '../assets/signDocsIconWhite.png'
import unsentFooter from '../assets/unsentFooter.png'
import sentFooter from '../assets/sentFooter.png'
import completeFooter from '../assets/completeFooter.png'

import submitButton from '../assets/submitButton.png'

import Disclosures from '../components/Disclosures'
import FileUploadButton from '../components/FileUploadButton.tsx';
import { useParams } from 'react-router-dom'
import {
  GetProposalInformationResponse,
  SubmitCreditApplicationResponse,
  SubmitCreditApplicationHomeOwnership,
  SubmitCreditApplicationCitizenshipStatus,
  SubmitCreditApplicationEmploymentStatus,
  SubmitCreditApplicationHomeOccupancy,
  FinanceApplicationScene,
  GetSendDocsLinkResponse,
} from '../gen/finance_tool/v1/service_pb.ts'


import {
  StatusInfo,
  LeadInfo,
  ProjectInfo,
  DisclosureInfo,
  submitCreditApplicationParams,
} from '../types'

import dataProvider from '../network.ts'

import {
  H2,
  StyledInput,
  StyledLabel,
  P,
} from '../components/StyledComponents'
import DatePicker from '../components/DatePicker.tsx';
import DropDown from '../components/DropDown.tsx';
import axios from 'axios';
const url = "https://api.sunobi.com"
//const url = "https://localhost:5001"

const translateInfoToLead = (
  response: GetProposalInformationResponse,
): Partial<LeadInfo> => ({
  leadName: response.primaryName,
  leadAddress: response.addressString,
  leadPhone: response.phone,
  leadEmail: response.email,
  repFullName: response.repName,
  repCompany: response.repCompany,
})

const translateInfoToProject = (
  response: GetProposalInformationResponse,
): Partial<ProjectInfo> => ({
  installerName: response.installerName,
  installTeamName: response.installTeamName,
  systemSizeInWatts: response.systemSizeInWatts,
  numberOfPanels: response.numberOfPanels,
  estimatedAnnualProductionInWatts: response.estimatedAnnualProductionInWatts,
  initialPaymentInCents: response.initialPaymentInCents,
  tpoEscalator: response.tpoEscalator,
  tpoKwhRate: response.tpoKwhRate,
  utilityCostInCents: response.utilityCostInCents,
  utilityConsumptionInWatts: response.utilityConsumptionInWatts,
  utilityEscalator: response.utilityEscalator,
})


const prettifyEnumValue = (enumValue: string) => {
  return enumValue
    .toLowerCase() // Convert the whole string to lowercase
    .split('_') // Split the string into an array of words based on underscore
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ') // Join all words with a space
}

export default function Root() {
  let { id, uuid } = useParams()
  const [submittingIds, setSubmittingIds] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [sentAt, setSentAt] = useState(false)
  const [status, setStatus] = useState<StatusInfo>({
    sceneToShow: FinanceApplicationScene.LOADING,
    docsSent: false,
    goodleapApplicationJson: {},
    cases: [],
    casesUrl: ""
  })

  const [leadInfo, setLeadInfo] = useState<LeadInfo>({
    leadName: '',
    leadAddress: '',
    leadPhone: '',
    leadEmail: '',
    repFullName: '',
    repCompany: '',
  })

  const [projectInfo, setProjectInfo] = useState<ProjectInfo>({
    installerName: '',
    installTeamName: '',
    systemSizeInWatts: 0,
    numberOfPanels: 0,
    estimatedAnnualProductionInWatts: 0,
    initialPaymentInCents: 0,
    tpoEscalator: 0,
    tpoKwhRate: 0,
    utilityCostInCents: 0,
    utilityConsumptionInWatts: 0,
    utilityEscalator: 0,
  })

  const [disclosureInfo, setDisclosureInfo] = useState<DisclosureInfo>({
    token: '',
    sections: [],
    completed: false,
    signatureImage: '',
  })

  const [financeApplication, setFinanceApplication] =
    useState<submitCreditApplicationParams>({
      id: id || '',
      uuid: uuid || '',
      homeOccupancy: "PRIMARY",
    })

  const updateLeadInfo = (info: Partial<LeadInfo>) => {
    setLeadInfo((prev: LeadInfo) => ({ ...prev, ...info }))
  }

  const updateProjectInfo = (info: Partial<ProjectInfo>) => {
    setProjectInfo((prev: ProjectInfo) => ({ ...prev, ...info }))
  }

  const updateApplication = (key: string, value: any) => {
    setFinanceApplication((prev: submitCreditApplicationParams) => ({
      ...prev,
      [key]: value,
    }))
  }
  var getCasesInterval;
  var updateStatusInterval;
  const updateAccountStatusRunner = async (id: string, uuid : string) => {
    const res = await dataProvider.getStatus({ id, uuid })
    const cast: StatusInfo = {
      sceneToShow: res.sceneToShow,
      docsSent: res.docsSent,
      goodleapApplicationJson: {},
      cases: []
    }
    if(getCasesInterval == null && !isSubmitting)
    {
      setStatus(cast)
      if(cast.sceneToShow == FinanceApplicationScene.NEED_STIPULATIONS)
      {
        clearInterval(updateStatusInterval);
        getCasesInterval = setInterval(() =>
        {
          updateAccountCasesRunner(id)
        }, 15000)
        updateAccountCasesRunner(id);
      }
    }
  }
  function GetCases(id)
  {
      let body = 
      {
          proposalId: id
      };

      return axios.post(url + '/goodleap/getcases', JSON.stringify(body),
      {
          headers:
          {
              'Content-Type': 'application/json'
          }
      })
      .then(response =>
      {
          return response.data;
      })
      .catch(error =>
      {
          console.error('Error checking proposal:', error);
          throw error; // Optionally re-throw the error after logging it
      });
  }
  function GetStatus(id)
  {
      let body = 
      {
          proposalId: id
      };

      return axios.post(url + '/goodleap/getstatus', JSON.stringify(body),
      {
          headers:
          {
              'Content-Type': 'application/json'
          }
      })
      .then(response =>
      {
          return response.data;
      })
      .catch(error =>
      {
          console.error('Error checking proposal:', error);
          throw error; // Optionally re-throw the error after logging it
      });
  }
  function GetCaseUrl(proposalId, caseId, docType, mimeType)
  {
      let body = 
      {
          proposalId: proposalId,
          caseId: caseId,
          docType: docType,
          mimeType: mimeType
      };

      return axios.post(url + '/goodleap/getcaseurl', JSON.stringify(body),
      {
          headers:
          {
              'Content-Type': 'application/json'
          }
      })
      .then(response =>
      {
          return response.data;
      })
      .catch(error =>
      {
          console.error('Error checking proposal:', error);
          throw error; // Optionally re-throw the error after logging it
      });
  }
  const updateAccountCasesRunner = async (id: string) =>
  {
    let cases = await GetCases(id);
    let filteredCases = cases.filter((item) => item.status == "OPEN" && (item.caseKey == "stip.identity" || item.caseKey == "stip.propertytype.solarbattery"));
    console.log(status);
    status.sceneToShow = FinanceApplicationScene.NEED_STIPULATIONS;
    status.cases = filteredCases;
    if(filteredCases.length == 0)
    {
      clearInterval(getCasesInterval);
      status.sceneToShow = FinanceApplicationScene.SEND_DOCS;
    }
    setStatus({...status});
  }
  useEffect(() => {
    try {
      if (!id || !uuid) {
        throw new Error('Invalid parameters')
      }
    }
    catch(error){
      console.error('Error fetching data: ', error)
      return
    }
    function CheckProposal(id)
    {
        let body = 
        {
            proposalId: id
        };

        return axios.post(url + '/financecheck/check', JSON.stringify(body),
        {
            headers:
            {
                'Content-Type': 'application/json'
            }
        })
        .then(response =>
        {
            return response.data;
        })
        .catch(error =>
        {
            console.error('Error checking proposal:', error);
            throw error; // Optionally re-throw the error after logging it
        });
    }
    
    async function initialFetch() {
      try {
        if (!id || !uuid) {
          throw new Error('Invalid parameters')
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
        return 
      }
      updateAccountStatusRunner(id, uuid)
      const response: GetProposalInformationResponse = await dataProvider.getAccountInformation({ id, uuid })
      updateLeadInfo(translateInfoToLead(response))
      updateProjectInfo(translateInfoToProject(response))
      setDisclosureInfo({
        token: response.disclosuresToken,
        sections: response.disclosureSections,
        completed: false,
        signatureImage: '',
      })
    }

    CheckProposal(id).then(json =>
    {
      if(json.issues != null && json.issues.length > 0)
      {
        let issues = "";
        for(let i = 0; i < json.issues.length; i++)
        {
          issues += "Goodleap does not approve of : " + json.issues[i].type + " : " + json.issues[i].manufacturer + " : " + json.issues[i].model + " : " + json.issues[i].name + " ";
        }
        alert(issues);
      }
      else
      {
        updateStatusInterval = setInterval(() =>
        {
          updateAccountStatusRunner(id, uuid)
        }, 15000)
        initialFetch();
      }
    }).catch(error =>
    {
        alert('Error checking proposal:');
        throw error;
    });
    
  }, [])
  

  const isApplicationComplete = () =>
  {
    /*
    id: string,
    uuid: string,
    ssn?: string,
    dob?: string,
    annualIncome?: string,
    employer?: string,
    employmentStatus?: EmploymentStatus,
    employmentOccupation?: string,
    employmentDuration?: string,
    homeOccupancy?: HomeOccupancy,
    homeOwnership?: HomeOwnership,
    citizenshipStatus?: CitizenshipStatus,
    disclosureToken?: string,
    signatureImage?: string,
    */
    if (
      !financeApplication.dob ||
      !financeApplication.annualIncome ||
      //!financeApplication.employer ||
      //!financeApplication.employmentStatus ||
      !financeApplication.employmentOccupation ||
      //!financeApplication.employmentDuration ||
      !financeApplication.homeOccupancy ||
      !financeApplication.homeOwnership ||
      //!financeApplication.citizenshipStatus ||
      //!financeApplication.disclosureToken ||
      //!financeApplication.signatureImage ||
      !financeApplication.ssn
    ) {
      return false
    }

    return true
  }

  const clickSubmitApplication = async () => {

    if(!isApplicationComplete())
    {
      alert('Please fill out all required fields');
      return;
    }
    if (isSubmitting) {
      alert("Currently submitting")
      return
    }
    setIsSubmitting(true)
    try {
      if (!id || !uuid) {
        throw new Error('Invalid parameters')
      }
      const body: submitCreditApplicationParams = financeApplication
      console.log('clickSubmitApplication', body, { disclosureInfo })
      body.disclosureToken = disclosureInfo.token
      body.signatureImage = disclosureInfo.signatureImage

      const response = await dataProvider.submitAccountInformation(body)
      // Here we do stuff
      console.log({"clickSubmitRes" : response})
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  const clickSendDocs = async () => {
    if (isSending) {
      alert('Currently sending')
      return
    }
    
    setIsSending(true)
    try {
      if (!id || !uuid) {
        throw new Error('Invalid parameters')
      }
      setSentAt(new Date().toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }));
      const response : GetSendDocsLinkResponse =  await dataProvider.sendDocs({ id, uuid })
      setIsSending(false)
      setIsSent(true);
      setTimeout(() =>
      {
        setIsSent(false);
      }, 120000)
    } catch (error) {
      setIsSending(false)
    }
  }

  const getSpecificEnumValues = specificEnum => {
    const metadata = proto3.getEnumType(specificEnum)
    const enumArray = metadata.values
    return enumArray
  }
  const GetFileExtension = (filename) =>
  {
    var parts = filename.split('.');
    if (parts.length === 1 || (parts[0] === "" && parts.length === 2))
    {
        return "";
    }
    return parts[parts.length - 1];
  }
  const GetMimeType = (extension) =>
  {
    switch(extension.toLowerCase())
    {
      case "jpg":
      case "jpeg":
      {
        return "image/jpeg";
      }
      case "png":
      {
        return "image/png";
      }
      case "heic":
      {
        return "image/heic";
      }
      case "heif":
      {
        return "image/heif";
      }
      default:
      {
        return "application/pdf";
      }
    }
  }
  const handleFileUpload = async (event, item, document) => 
  {
      const file = event.target.files[0];
      submittingIds.push(item.id);
      setSubmittingIds(submittingIds);
      setStatus({...status});
      console.log(`File uploaded for ${JSON.stringify(item)}:`, file.name);      
      var caseUrl: any = await GetCaseUrl(id, item.id, document.docType, GetMimeType(GetFileExtension(file.name)))
      try 
      {
          const response = await fetch(caseUrl.uploadUrl,
          {
              method: 'PUT',
              body: file,
          });
          if (response.ok) 
          {
            event.target.value = "";
            await updateAccountCasesRunner(String(id));
          } 
      } 
      catch (error) 
      {
          console.error('Error during file upload', error);
      }
  };
    
  const renderFn = () => {
    const isCompleted = false
    switch (status.sceneToShow) {
      case FinanceApplicationScene.LOADING:
        return (
          <div css={tw`min-h-screen flex flex-col items-center w-full bg-gradient-to-tr from-gray-100 to-gray-300 pt-8`}>
            <h3 css={tw`text-3xl font-medium mb-2 uppercase tracking-widest`}>
              Finance Loading
            </h3>
            <h4>This should only take a moment</h4>
            <img src={animatedLoading} tw="h-32"/>
          </div>
        )
      case FinanceApplicationScene.NEED_STIPULATIONS:
          return (
            <div css={tw`min-h-screen flex flex-col items-center w-full bg-gradient-to-tr from-gray-100 to-gray-300 pt-8`}>
              <h4 css={tw`text-3xl font-medium mb-2 text-gray-500`}>Result</h4>
              <div css={tw`flex items-baseline`}>
                <h4 css={tw`text-5xl text-green-500`}>APPROVED</h4>
              </div>
              <h5 css={tw`pt-[20px]`}>Great! This Finance option has been approved with the following stipulations</h5>
              <div css={tw`flex flex-wrap`}>
              {
                status.cases.map((item, index) =>
                  <div key={index} css={tw`pt-[50px]`}>
                    <h2>{item.name}</h2>
                    <div css={tw`flex flex-wrap pt-[50px]`}>
                    {
                        item.status === "OPEN" && item.currentStep.actor === "GOODLEAP"
                        ?
                        (
                          <div>
                            <div css={tw`flex items-center justify-center bg-orange-500 text-white font-bold py-2 px-4 rounded-full hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50`}>
                              Submitted
                            </div>
                            <div>Awaiting Approval.</div>
                          </div>
                        )
                        :
                        (
                          <div>
                          {
                            item.status === "OPEN" && submittingIds.includes(item.id)
                            ?
                            (
                              <div css={tw`flex items-center justify-center bg-gray-500 text-white font-bold py-2 px-4 rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50`}>
                                Submitting
                              </div>
                            )
                            :
                            (
                              <div>
                              {
                                item.status === "OPEN" ?
                                (
                                  <div>
                                    {
                                      item.documentsToClear.map((document, documentIndex) =>
                                      (
                                        <div key={documentIndex}>
                                          {
                                            documentIndex > 0 ? 
                                            (<div css={tw`text-center`}>or</div>):(<div></div>) 
                                          }
                                          <div css={tw`text-center`}>{document.name}</div>
                                          <FileUploadButton onChange={(evt) => handleFileUpload(evt, item, document)} />
                                          <div style={{ display: 'none' }}>{JSON.stringify(item)}</div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                )
                                :
                                (
                                  <div css={tw`flex items-center justify-center bg-green-500 text-white font-bold py-2 px-4 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50`}>
                                    Approved
                                  </div>
                                )
                              }
                              </div>
                            )
                          }
                        </div>                              
                      )
                    }
                    </div>
                  </div>
                )
              }
              </div>
            </div>
          )
      case FinanceApplicationScene.APPLICATION:
        if(!(projectInfo.installerName && projectInfo.installTeamName != '' ))
        {
          return (
            <div css={tw`min-h-screen flex flex-col items-center w-full bg-gradient-to-tr from-gray-100 to-gray-300 pt-8`}>
              <h3 css={tw`text-3xl font-medium mb-2 uppercase tracking-widest`}>
                Finance Loading
              </h3>
              <h4>This should only take a moment</h4>
              <img src={animatedLoading} tw="h-32"/>
            </div>
          )
        }
        
        const homeOwnershipOptions = getSpecificEnumValues(
          SubmitCreditApplicationHomeOwnership,
        )
        const citizenshipStatusOptions = getSpecificEnumValues(
          SubmitCreditApplicationCitizenshipStatus,
        )
        const employmentOptions = getSpecificEnumValues(
          SubmitCreditApplicationEmploymentStatus,
        )
        const homeOccupancyOptions = getSpecificEnumValues(
          SubmitCreditApplicationHomeOccupancy,
        )

        if(isSubmitting)
        {
          return (
            <div css={tw`min-h-screen flex flex-col items-center w-full bg-gradient-to-tr from-gray-100 to-gray-300 pt-8`}>
              <h3 css={tw`text-3xl font-medium mb-2 uppercase tracking-widest`}>
                Submitting
              </h3>
              <h4>Usually within 30 seconds</h4>
              <h5>Can sometimes take up to 2 minutes</h5>
              <img src={animatedLoading} tw="h-32"/>
            </div>
          )
        }

        return (
          <div css={tw`min-h-screen overflow-x-hidden flex flex-col items-center w-full bg-gradient-to-tr from-gray-100 to-gray-300`}>
            <div css={tw`flex flex-wrap w-10/12 mt-12`}>
              {projectInfo.installerName &&
              projectInfo.installTeamName != '' ? (
                <div css={tw`w-full flex flex-wrap`}>
                  <div css={tw`border-gray-900/10 w-9/12 mb-8 p-5 border bg-white rounded`}>
                    <H2>Project Details</H2>
                    <div css={tw`grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 my-2 `}>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Installer</StyledLabel>
                        <P>{projectInfo.installerName}</P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Install Team</StyledLabel>
                        <P>{projectInfo.installTeamName}</P>
                      </div>
                    </div>
                    <div css={tw`grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Number of Panels</StyledLabel>
                        <P>{projectInfo.numberOfPanels}</P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>System Size</StyledLabel>
                        <P>
                          {(projectInfo.systemSizeInWatts / 1000).toFixed(3)} kW
                        </P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Estimated Annual Production</StyledLabel>
                        <P>
                          {(
                            projectInfo.estimatedAnnualProductionInWatts / 1000
                          ).toFixed(2)}{' '}
                          kWh
                        </P>
                      </div>
                    </div>
                    <H2 css={tw`mt-6`}>Utility Costs</H2>
                    <div css={tw`grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Current monthly payment</StyledLabel>
                        <P>
                          $
                          {(projectInfo.utilityCostInCents / 100 / 12).toFixed(
                            2,
                          )}
                        </P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>kWh Rate</StyledLabel>
                        <P>{(projectInfo.utilityCostInCents / (projectInfo.utilityConsumptionInWatts / 1000)).toFixed(2)} cents</P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Yearly Escalator</StyledLabel>
                        <P>
                          {(projectInfo.utilityEscalator * 100).toFixed(2)}%
                        </P>
                      </div>
                    </div>
                    <H2 css={tw`mt-6`}>Solar Finance</H2>
                    <div css={tw`grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Initial monthly payment</StyledLabel>
                        <P>
                          {(projectInfo.initialPaymentInCents / 100).toFixed(2)}
                        </P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>kWh Rate</StyledLabel>
                        <P>{(projectInfo.tpoKwhRate * 100).toFixed(2)} cents</P>
                      </div>
                      <div css={tw`sm:col-span-4`}>
                        <StyledLabel>Yearly Escalator</StyledLabel>
                        <P>{(projectInfo.tpoEscalator * 100).toFixed(2)}%</P>
                      </div>
                    </div>
                  </div>
                  <div css={tw`w-3/12 `}>
                    <img src={bankOnly} css={tw`w-full`} />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/*
                "installerName": "Fake Installer",
                "installTeamName": "Fake Install team",

                "systemSizeInWatts": 3480,
                "numberOfPanels": 12,
                "estimatedAnnualProductionInWatts": 5550606,

                "initialPaymentInCents": 6303,
                "tpoEscalator": 0.0399,
                "tpoKwhRate": 0.11999999731779099,

                "utilityCostInCents": 240000,
                "utilityConsumptionInWatts": 6109750,
                "utilityEscalator": 0.04
              */}

              <div css={tw`border-gray-900/10 w-full`}>
                <H2>Applicant</H2>
                <div css={tw`mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Lead Name</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        type="text"
                        value={leadInfo.leadName}
                        readonly
                        isReadOnly
                      />
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Email</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        type="text"
                        value={leadInfo.leadEmail}
                        readonly
                        isReadOnly
                      />
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Phone</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        type="text"
                        value={leadInfo.leadPhone}
                        readonly
                        isReadOnly
                      />
                    </div>
                  </div>

                  <div css={tw`sm:col-span-6`}>
                    <StyledLabel>Address</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        type="text"
                        value={leadInfo.leadAddress}
                        readonly
                        isReadOnly
                      />
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Home Ownership</StyledLabel>
                    <div css={tw`mt-2`}>
                      <DropDown
                        isRequired
                        isEmpty = {!financeApplication.homeOwnership}
                        onChange={e =>
                          updateApplication('homeOwnership', e.target.value)
                        }
                      >
                        <option value="">Please Choose</option>
                        {homeOwnershipOptions.map(o => (
                          <option value={o.name}>
                            {prettifyEnumValue(o.name)}
                          </option>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Home Occupancy</StyledLabel>
                    <div css={tw`mt-2`}>
                      <DropDown
                        isEmpty = {!financeApplication.homeOccupancy}
                        value={financeApplication.homeOccupancy || homeOccupancyOptions[0]?.name}
                        onChange={e =>
                          updateApplication('homeOccupancy', e.target.value)
                        }
                      >
                        {homeOccupancyOptions.map(o => (
                          <option key={o.name}  value={o.name}>
                            {prettifyEnumValue(o.name)}
                          </option>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                </div>
              </div>

              <div css={tw`pb-12 w-full`}>
                <div css={tw`mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>SSN (full or last four digits)</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        isRequired
                        isEmpty = {!financeApplication.ssn}
                        type="number"
                        value={financeApplication.ssn}
                        onChange={e => {
                          updateApplication('ssn', e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Birthdate</StyledLabel>
                    <div css={tw`mt-2`}>
                      <DatePicker
                        isRequired
                        isEmpty = {!financeApplication.dob}
                        type="date"
                        value={financeApplication.dob}
                        onChange={e => {
                          updateApplication('dob', e.target.value)
                        }}
                      />
                    </div>
                  </div>

                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Citizenship</StyledLabel>
                    <div css={tw`mt-2`}>
                      <DropDown
                        //isRequired
                        isEmpty = {!financeApplication.citizenship}
                        onChange={e =>
                          updateApplication('citizenship', e.target.value)
                        }
                      >
                        <option value="">Please Choose</option>
                        {citizenshipStatusOptions.map(o => (
                          <option value={o.name}>
                            {prettifyEnumValue(o.name)}
                          </option>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Annual Income</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        isRequired
                        isEmpty = {!financeApplication.annualIncome}
                        type="number"
                        value={financeApplication.annualIncome}
                        onChange={e =>
                          updateApplication('annualIncome', e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div css={tw`border-b border-gray-900/10 pb-12 w-full`}>
                <h2 css={tw`text-lg font-semibold leading-7 text-gray-900`}>
                  Employment Information
                </h2>
                <div css={tw`mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Employer</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        isEmpty = {!financeApplication.employer}
                        type="text"
                        value={financeApplication.employer}
                        onChange={e => {
                          updateApplication('employer', e.target.value)
                        }}
                      />
                    </div>
                  </div>

                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Occupation</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        isRequired
                        isEmpty = {!financeApplication.employmentOccupation}
                        type="text"
                        value={financeApplication.employmentOccupation}
                        onChange={e => {
                          updateApplication(
                            'employmentOccupation',
                            e.target.value,
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Status</StyledLabel>
                    <div css={tw`mt-2`}>
                      <DropDown
                        isEmpty = {!financeApplication.employmentStatus}
                        onChange={e =>
                          updateApplication('employmentStatus', e.target.value)
                        }
                      >
                        <option value="">Please Choose</option>
                        {employmentOptions.map(o => (
                          <option value={o.name}>
                            {prettifyEnumValue(o.name)}
                          </option>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                  <div css={tw`sm:col-span-3`}>
                    <StyledLabel>Duration</StyledLabel>
                    <div css={tw`mt-2`}>
                      <StyledInput
                        //isRequired
                        isEmpty = {!financeApplication.employmentDuration}
                        type="number"
                        value={financeApplication.employmentDuration}
                        onChange={e => {
                          updateApplication(
                            'employmentDuration',
                            e.target.value,
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div css={tw`w-full my-12`}>
                  <H2>Rep Information</H2>
                  <div css={tw`mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12 w-full`}>
                    <div css={tw`sm:col-span-3`}>
                      <StyledLabel>Name</StyledLabel>
                      <div css={tw`mt-2`}>
                        <StyledInput
                          type="text"
                          value={leadInfo.repFullName}
                          readonly
                          isReadOnly
                        />
                      </div>
                    </div>
                    <div css={tw`sm:col-span-3`}>
                      <StyledLabel>Company</StyledLabel>
                      <div css={tw`mt-2`}>
                        <StyledInput
                          type="text"
                          value={leadInfo.repCompany}
                          readonly
                          isReadOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosures
                  info={disclosureInfo}
                  setInfo={setDisclosureInfo}
                />
                {/*
                    <h3 css={tw`text-lg mt-8`}>Disclosures Completed</h3>
                    <p>{disclosureInfo.completed ? 'YES' : 'NO'}</p>
                */}
              </div>
              <div css={tw`flex justify-end flex-1 mt-12 mb-24`}>
                <div
                  css={tw`w-44 bg-[#33A9FF] rounded-full flex justify-center items-center py-4  cursor-pointer`}
                  onClick={clickSubmitApplication}
                >
                  <img src={submitButton} css={tw`h-4 mr-1`} />
                  <p css={tw`text-center text-white text-sm uppercase font-medium tracking-widest`}>
                    {isSubmitting ? 'Please wait' : 'Submit'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      case FinanceApplicationScene.SEND_DOCS:
        return (
          <>
            <div css={tw`min-h-screen flex flex-col items-center w-full bg-gradient-to-tr from-gray-100 to-gray-300 pt-8`}>
              <h3 css={tw`text-3xl font-medium mb-2 uppercase tracking-widest`}>
                Finance Approved!
              </h3>
              <h3 css={tw`text-xl font-light`}>
                {'Completed ' +
                  (!isCompleted ? 'upon approval of signed ' : '') +
                  'Finance Docs'}
              </h3>
              <img src={bankOnly} css={tw`h-64`} />
              <div css={tw`w-[1300px] flex flex-col justify-center items-center relative`}>
                {status.docsSent ? (
                  isCompleted ? (
                    <>
                      <img
                        src={bigTick}
                        css={tw`w-2/12 absolute right-[450px] top-[-50px]`}
                      />
                      <div
                        css={tw`w-64 bg-white rounded-full py-4 flex justify-center items-center cursor-pointer mr-72`}
                        onClick={() => alert('In Development')}
                      >
                        <img src={signDocsIconBlack} css={tw`h-8 mr-5`} />
                        <p css={tw`text-center text-lg uppercase font-medium tracking-widest cursor-pointer`}>
                          View Docs
                        </p>
                      </div>
                    </>
                  ) : (
                    <div css={tw`flex w-1/2 justify-between`}>
                      <div
                        css={tw`w-64 bg-white rounded-full py-4 flex justify-center items-center  cursor-pointer`}
                        onClick={() => {}}
                      >
                        <img src={sendDocsIcon} css={tw`h-8 mr-5`} />
                        <p css={tw`text-center text-lg uppercase font-medium tracking-widest cursor-pointer`}>
                          ReSend Docs
                        </p>
                      </div>
                      <div
                        css={tw`w-64 bg-[#33A9FF] rounded-full py-4 flex justify-center items-center  cursor-pointer`}
                        onClick={() => {}}
                      >
                        <img src={signDocsIcon} css={tw`h-8 mr-5`} />
                        <p css={tw`text-center text-lg text-white uppercase font-medium tracking-widest cursor-pointer`}>
                          Sign Docs
                        </p>
                      </div>
                    </div>
                  )
                ) : (
                  isSent ? (
                    <>
                    <div
                      css={tw`w-64 bg-white rounded-full py-4 flex justify-center items-center cursor-pointer`}
                    >
                      <img src={sendDocsIcon} css={tw`h-8 mr-5`} />
                      <p css={tw`text-center text-lg uppercase font-medium tracking-widest cursor-pointer`}>
                        Docs Sent
                      </p>
                    </div>
                    <p css={tw`text-center text-lg uppercase font-medium tracking-widest cursor-pointer`}>
                        at {sentAt}
                    </p>
                  </>
                  ) : (
                  <>
                    <div
                      css={tw`w-64 bg-white rounded-full py-4 flex justify-center items-center cursor-pointer`}
                      onClick={clickSendDocs}
                    >
                      <img src={sendDocsIcon} css={tw`h-8 mr-5`} />
                      <p css={tw`text-center text-lg uppercase font-medium tracking-widest cursor-pointer`}>
                        {isSending ? "Sending Docs" : "Send Docs" }
                      </p>
                    </div>
                  </>
                  )
                )}
              </div>
            </div>
          </>
        )
      default:
        return <div>Unknown scene</div>
    }
  }

  return renderFn()
}
